@import url('https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$blue: #495AFF;
$lavender: #CA7FFE;
$lime: #D8FE59;
$lightgray: #F6F5F1;


@mixin poppins {
    font-family: "Poppins", sans-serif; 
}
@mixin tiltBase {
    font-family: "Tilt Neon", sans-serif;
}
@mixin tilt {
    font-family: "Tilt Neon", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  filter: drop-shadow( 0px 0px 2px rgba(255, 145, 23, 0.75)); 
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #FF9117;
    font-family: "Tilt Neon";
    font-size: 1rem;  
    line-height: normal;
    letter-spacing: 1.12px;
    text-transform: uppercase;
    color: #FFDF22;
}

@mixin border {
    outline: 1.5px solid #FF9117;
    border: 1.5px solid #FFDF22;
    box-shadow: inset 0px 0px 0px  1.5px #FF9117;
}

@mixin breakpoint($width) {
    @if $width == mobil {
        @media (max-width: 414px) { @content; }
    } 
    @else if $width == tablet {
        @media (max-width: 1000px) { @content; }
    }
    @else if $width == maxitablet {
        @media (max-width: 1799px) { @content; }
    }
    @else if $width == laptop {
        @media (min-width: 1000px) { @content; }
    }
    @else if $width == desktop {
        @media (min-width: 1800px) { @content; }
    }
    @else if $width == megadesktop {
        @media (min-width: 2560px) { @content; }
    } 
}