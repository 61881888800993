.petice {
    min-height: 80vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center; 
    z-index: 1;
   &::before {
        content: "";
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        background: url("../assets/svg/PETICE_LIGHT.svg") no-repeat;
        background-position: left top;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        opacity: 0.1;
        mix-blend-mode: soft-light;
        background-size: 100%;
    }
    &::after {
        content: "";
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        background: url("../assets/svg/PETICE_LINES_2.webp") no-repeat;
        background-position: left top;
        background-repeat: no-repeat;
        position: absolute;
        top: 0; 
        mix-blend-mode: soft-light;
        background-size: 100%;
        opacity: 0.2;
    }
    &__box {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;

        @include breakpoint(desktop) {
            width: 45vw;
        }

        @include breakpoint(tablet) {
            width: 90vw;
            margin-top: 5rem;
        }


        @include breakpoint(mobil) {
            width: 80vw;
        }

        span,
        p,
        h2 {
            text-align: center;
        }


        button {
            margin-top: 3rem;
        }

        h2 {
            margin: auto;
        }


        .perex h2::after {
            content: "";
            background: url("../assets/svg/VYBUCH.svg") no-repeat;
            position: absolute;
            display: inline-block;

            @include breakpoint(desktop) {
                top: -20%;
                right: -20%;
                width: 130px;
                height: 155px;
                background-size: 100%;
            }

            @include breakpoint(maxitablet) {
                top: -10%;
                right: -20%;
                width: 130px;
                height: 155px;
                background-size: 80%;
            }

            @include breakpoint(tablet) {
                top: 0;
                right: -60px;
                background-size: 90%;
                width: 80px;
                height: 100px;
            }

            @include breakpoint(mobil) {
                top: 28%;
                right: -15%;
                width: 80px;
                background-size: 70%;
            }
        }

        .perex h2::before {
            content: "";
            background: url("../assets/svg/WARNING.webp") no-repeat;
            position: absolute;
            display: inline-block;

            @include breakpoint(desktop) {
                top: 25%;
                left: -30%;
                background-size: 100%;
                width: 130px;
                height: 155px;
            }

            @include breakpoint(maxitablet) {
                top: 25%;
                left: -25%;
                background-size: 80%;
                width: 130px;
                height: 155px;
            }

            @include breakpoint(tablet) {
                top: -20px;
                left: -60px;
                background-size: 90%;
                width: 80px;
                height: 100px;
            }

            @include breakpoint(mobil) {
                top: 20%;
                left: -50px;
                background-size: 80%;
                width: 80px;
                height: 100px;
            }
        }

        .perex span {
            width: min-content;
            margin: auto;

            &::after {
                content: "";
                background: url("../assets/svg/STOP.webp") no-repeat;
                position: absolute;
                display: inline-block;

                @include breakpoint(desktop) {
                    top: 5%;
                    left: -730%;
                    background-size: 100%;
                    width: 130px;
                    height: 155px;
                }

                @include breakpoint(maxitablet) {
                    top: -50px;
                    left: -550px;
                    background-size: 80%;
                    width: 130px;
                    height: 155px;
                }

                @include breakpoint(tablet) {
                    top: -20px;
                    left: -250px;
                    background-size: 100%;
                    width: 80px;
                    height: 100px;
                }


                @include breakpoint(mobil) {
                    left: -150px;
                    top: 60%;
                    background-size: 80%;
                    width: 80px;
                    height: 100px;
                }
            }
        }




    }
}