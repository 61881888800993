.intro {
    width: 100%;
    padding: 5% 5% 6rem;
    height: 100vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @include breakpoint(maxitablet) { 
        padding: 10rem 5% 6rem;
    }

    @include breakpoint(tablet) {
        height: auto;
        padding: 5rem 5% 6rem;
        flex-direction: column;

        img {
            max-height: 50vh;
        }
    }

    @include breakpoint(mobil) {
        width: 90vw;
    }

    &__hero {
        width: 50%;
        margin-top: 0;


        img {
            width: 90%;
            max-width: 100vw;
            text-align: center;
            object-fit: contain;
            @include breakpoint(tablet) {
                width: 100%;  
                z-index: 1;
            } 
        }

        &::before {
            content: "";
            width: -webkit-fill-available;
            height: -webkit-fill-available;
            background: url("../assets/svg/HERO_Light_bottom.svg") no-repeat;
            background-size: 100%;
            background-position: center left;
            background-repeat: no-repeat;
            position: absolute;
            left: 0;
            filter: blur(55px);
            mix-blend-mode: soft-light;
        }

        &::after {
            content: "";
            width: -webkit-fill-available;
            height: -webkit-fill-available;
            background: url("../assets/svg/HERO_Light_on_top.svg") no-repeat;
            position: absolute;
            left: 0;
            filter: blur(10px);
            opacity: 0.5;
            background-size: 100%;
            mix-blend-mode: soft-light;

            @include breakpoint(tablet) {
                filter: blur(35px);
                width: 90%;
            }
        }

        @include breakpoint(tablet) {
            width: 100%;
        }
 
    }




    &__title {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-width: 700px;
        width: 50%;
        z-index: 1;

        @include breakpoint(maxitablet) {
            padding-top: 0;
        }

        @include breakpoint(tablet) {
            padding-top: 5vh;
            max-width: 100vw;
            width: 100%; 
        }
 

        h1 {
            font-size: 4rem;

            @include poppins;
            color: white;
            text-transform: uppercase;
            line-height: 1.3;

            mark {
                @include tilt;
                font-size: 4rem;
                -webkit-text-stroke-width: 3px;
                &:before { content: ' '; display: block; }
                @include breakpoint(mobil) {
                    font-size: 2.7rem;
                    -webkit-text-stroke-width: 2px;
                    &:before { display: none; }
                }
            }

            @include breakpoint(mobil) {
                font-size: 2.7rem;
            }
        }

        p {
            color: white;
            max-width: 500px;
            font-size: 1.2rem;
        }

        button {
            margin-top: 3rem;

            @include breakpoint(tablet) {
                margin-top: 0;
            }
        }
    }
}