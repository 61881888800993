.terminy {
    width: 100%;
    padding: 0 5% 6rem;
    z-index: 1;

    @include breakpoint(mobil) {
        padding: 0 5% 3rem;

    }

    &__header {

        color: white;
        //padding: 200px 5% 4rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        @include breakpoint(laptop) { 
            padding: 13rem 5% 4rem;
        }
        @include breakpoint(desktop) { 
            padding: 0 5% 4rem;
        }
        @include breakpoint(tablet) {
            flex-direction: column;
            padding: 15rem 5% 4rem;
        }
        @include breakpoint(mobil) { 
            padding: 6rem 5% 1rem;
        }

        p {
            width: 60%;

            @include breakpoint(tablet) {
                width: 100%;
            }
        }

        &__title {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            width: 100%;
            max-width: 700px;


            @include breakpoint(mobil) {
                padding-top: 10vh;
            }

            h1 {
                font-size: 4rem;

                @include poppins;
                color: white;
                text-transform: uppercase;
                line-height: 1.3;

                mark {
                    @include tilt;
                    font-size: 4rem;
                    -webkit-text-stroke-width: 3px;

                    @include breakpoint(mobil) {
                        font-size: 1.8rem;
                        -webkit-text-stroke-width: 2px;
                    }
                }

                @include breakpoint(mobil) {
                    font-size: 1.8rem;
                }
            }
        }

        &__hero {
            width: 50%;

            img {
                width: 100%;
                max-width: 100vw;
                text-align: center;
            }

            &::before {
                content: "";
                width: -webkit-fill-available;
                height: -webkit-fill-available;
                background: url("../assets/svg/KALENDAR_LIGHT.svg") no-repeat;
                background-size: 100%;
                background-position: center left;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
                filter: blur(55px);
                mix-blend-mode: soft-light;
                opacity: 0.5;
            }

            @include breakpoint(tablet) {
                width: 70%;
            }

            @include breakpoint(mobil) {
                margin-top: 3rem;
            }
        }
    }

    &__box {
        width: 100%;
        padding: 5rem 10rem 10REM;
        border-radius: 32px;
        border: 3px solid #3e465c;
        background: linear-gradient(250deg, #1A1C31 0.33%, #111A23 100%), #111A23;
        z-index: 1;
        @include breakpoint(maxitablet) {
            padding: 5REM 5REM;
        }
        @include breakpoint(tablet) {
            padding: 2REM 2REM;
        }

        &__filter {
            padding-bottom: 4rem;
            display: flex;
            justify-content: center;
            gap: .5rem 2rem;
            flex-wrap: wrap;

            @include breakpoint(tablet) {
                padding-bottom: 2rem;
            }
            span {color: white; }
            a {
                color: white;
                text-transform: uppercase;
                text-decoration: none;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: .5px;
                    @include border;
                    background-color: #FFDF22;
                    border-radius: 2rem;
                    outline-width: 1.5px;
                    border-width: .3px;
                    box-shadow: none;

                    bottom: 5%;
                    left: 0;
                    pointer-events: none;
                    transform-origin: 100% 50%;
                    transform: scale3d(0, 1, 1);
                    transition: transform 0.3s;
                }

                &:not(.fancy):hover {
                    &:before {
                        transform-origin: 0% 50%;
                        transform: scale3d(1, 1, 1);
                    }
                }

                &.active:not(.fancy) {
                    &:before {
                        transform-origin: 0% 50%;
                        transform: scale3d(1, 1, 1);
                    }
                }
                &.fancy.active {@include tilt;}
            }
        }

        &__content {
            width: 100%;
            max-width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 2rem;
            @include breakpoint(maxitablet) { 
                grid-template-columns: 1fr 1fr;
            }
            @include breakpoint(tablet) {
                padding: 1REM 0;
                grid-template-columns: 1fr;
                gap: 1rem;
            }


            .event {
                width: 100%;

                &__body {
                    width: auto;
                }
            }
            &__pagination {
                width: 100%;
                text-align: center;
                color: white;
                a {
                    display: inline-block;
                    font-size: 1.25rem;
                    padding: 1rem 0.75rem;
                    &.active {
                        text-decoration: underline;
                        text-underline-offset: 4px;
                        text-decoration-thickness: 2px;
                    }
                }
            }
        }
    }
}

.gallery {
    padding-top: 5rem;
    width: 90%;
    margin: auto;
    .swiper {
        border-radius: 2rem;
        .swiper-button-prev, .swiper-button-next {
            width: 60px;
            height: 60px;
            @include tilt;
            font-size: 35px;
        }
        .swiper-slide img {
            min-height: 40vh;
            max-width: 100%;
            object-fit: cover;
        }
    }
}