.alert {
    font-family: "Tilt Neon", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    filter: drop-shadow(0px 0px 2px rgba(255, 145, 23, 0.75));
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #FF9117;
    font-family: "Tilt Neon";
    font-size: 1rem;
    line-height: normal;
    letter-spacing: 1.12px;
    text-transform: uppercase;
    color: #FFDF22;
    font-size: 2rem;
    text-transform: uppercase;

}

.elearning {

    &__box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        gap: 2rem;

        border-radius: 32px;
        border: 3px solid #3e465c;
        background: linear-gradient(250deg, #1A1C31 0.33%, #111A23 100%);
        padding: 8rem 14rem 8rem;

        button {
            margin: 3rem auto;
        }

        @include breakpoint(maxitablet) {
            padding: 5rem 7rem 5rem;
        }

        @include breakpoint(tablet) {
            padding: 5rem 1.5rem 5rem;
        }


        &__container {
            display: flex;
            gap: inherit;
            flex-wrap: wrap; 
            width: 100%;
            margin-bottom: 3rem;
    
            @include breakpoint(laptop) {
                flex-direction: row; 
            }
            @include breakpoint(tablet) {
                flex-direction: column;
            }
            @include breakpoint(maxitablet) {
                justify-content: left;           
            }
     
        }

    }
}

.elearning_question {
    @include border;
    padding: 1.5rem;
    border-radius: 1rem;
    background-color: #2D3047;
    color: white;
    font-size: 1.25rem;
    background-size: cover;
    background-repeat: no-repeat;
    @include breakpoint(tablet) {padding: 2rem;}
    span {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
    h3 {
        padding: 0.75rem 0 1.25rem;
        font-size: 1.5rem;
        text-transform: uppercase;
        color: white;
        @include breakpoint(desktop) {font-size: 1.5rem;}
    }
    a {
        @include tilt;font-size: 1.25rem;float: right;
        @include breakpoint(desktop) {font-size: 1.25rem;}
    }
    .SealCheck {
        position: absolute;
        right: 1rem;
        top: 1rem;
        @include tilt;
    }

    &.chodec {
        background-image: url(../assets/pov_chodec.png);
    }
    &.ridic {
        background-image: url(../assets/pov_ridic.png);
    }
}