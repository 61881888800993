.contact {
    width: 100%;
    padding: 0 5% 0;
    z-index: 1;

    @include breakpoint(tablet) {
        padding: 3rem 2.5%;
    }

    &__box {
        width: 100%;
        padding: 2REM 10REM;
        margin: auto;
        display: flex;
        margin-top: 0;
        border-radius: 32px;
        border: 3px solid #3e465c;
        background: linear-gradient(250deg, #1A1C31 0.33%, #111A23 100%), #111A23;

        @include breakpoint(maxitablet) {
            padding: 4rem 8rem;
        }

        @include breakpoint(tablet) {
            width: 100%;
            flex-direction: column;
            padding: 4rem 1rem;
        }


        &__left {
            width: 50%;
            padding: 6rem 0 6rem 6rem;

            @include breakpoint(maxitablet) {
                padding: 3rem 0;
            }

            @include breakpoint(tablet) {
                width: 100%;
                padding: 0;
            }

            img {
                max-width: 100%;
            }

            .socials {
                color: white;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding-top: 2rem;

                a {
                    display: flex;
                    align-items: center;
                    gap: .5rem;
                    cursor: pointer;
                    color: white;
                    font-weight: bold;
                }

                @include breakpoint(mobil) {
                    padding: 1rem 0;
                }
            }
        }

        &__right {
            width: 50%;

            img {
                max-width: 100%;
                max-height: 80%;
                text-align: center;
            }

            padding: 6rem 0 0 6rem;

            @include breakpoint(maxitablet) {
                margin: auto;
                max-height: 100%;
                padding: 0;
            }

            &::before {
                content: "";
                width: -webkit-fill-available;
                height: -webkit-fill-available;
                background: url("../assets/svg/KONTAKT_Light_bottom.svg") no-repeat;
                background-position: top left;
                background-repeat: no-repeat;
                position: absolute;
                left: 0rem;
                filter: blur(0);
                mix-blend-mode: soft-light;
                opacity: .4;
                background-size: 80%;

                @include breakpoint(maxitablet) {
                    background-size: 100%;
                }
            }


            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

}

.partners {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 6rem 2rem;
    z-index: 1;

    @include breakpoint(tablet) {
        flex-wrap: wrap;
        align-items: center;
    }

    img {
        max-width: 150px;
        object-fit: contain;
        object-position: bottom center;

        &.pv0 {
            max-width: 200px;
        }

        @include breakpoint(mobil) {
            max-width: 120px;
        }
    }

    a {
        &:nth-child(1) {
            img {
                max-width: 200px;

                @include breakpoint(tablet) {
                    max-width: 150px;
                }
            }

        }
    }
}