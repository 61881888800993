@import 'mixins';

* {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scrollbar-width: thin;
    line-height: 170%;
}

:root {
    font-size: 16px;  
    @include breakpoint(maxitablet) {
        font-size: 14px;
    }
}

body {
    @include poppins;
}

main {
    background-color: #2D3047;
    overflow-x: clip;
    &::before {
        content: "";
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        background: url("../assets/svg/HERO_MAIN_LARGE_LIGHT.svg") no-repeat;
        background-position: left top;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        mix-blend-mode: soft-light;
        background-size: 100%;
        opacity: .7; 
        background-position-x: center;
        @include breakpoint(tablet) {
            background-size: 160%;
        }
    }
    &::after {
        content: "";
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        background: url("../assets/svg/PETICE_LINES_2.webp") no-repeat;
        background-position: left top;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        mix-blend-mode: soft-light;
        background-size: 100%; 
        background-position-x: center;
        @include breakpoint(tablet) {
            background-size: 160%; 
        }
        @include breakpoint(mobil) { 
            background: url("../assets/svg/PETICE_LINES_mobile.webp") no-repeat;
        }
    }

}

h1,
h2,
h3,
h4 {
    @include poppins;
}

h3 {
    color: $blue;
    font-size: 2rem;
    padding-bottom: 1rem;
}

a {
    text-decoration: none
}

mark {
    all: unset
}
 
button, a.button {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    background-color: transparent;
    padding: 0.75rem 1.75rem;
    border-radius: 2rem;
    @include tilt;
    @include border;
    font-size: 1.6rem;
    @include breakpoint(mobil) {
        font-size: 0.8rem;
    }

    &:hover {
        filter: drop-shadow(0px 0px 4px rgba(255, 145, 23, 1));
        transition: all 0.1s ease-in;
    }

    &.transparent {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
    }

    
    @include breakpoint(tablet) {
        border-radius: 1.5rem;
    }
}

.perex {
    padding: 0 0 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 900px;
    color: white;

    span {
        @include tilt;
        font-size: 1.4rem; 
        text-transform: uppercase;
        
        @include breakpoint(mobil) {
            font-size: 1rem;
        }
    }

    p {
        font-size: 1.2rem; 

        @include breakpoint(mobil) {
            font-size: 1rem;
        }
    }

    h2 {
        font-size: 3.5rem;
        padding: 0 0 0.5rem 0;
        line-height: 1.25;
        
        text-transform: uppercase;

        * {
            line-height: 1;
        }

        @include breakpoint(mobil) {
            font-size: 2rem;
            padding: 0;
        }
    }
} 
.form { 
    border-radius: 1rem;

    button {
        margin-left: auto;

        @include breakpoint(mobil) {
            margin: auto;
            margin-top: 1rem
        }
    }
} 
@include breakpoint(mobil) {
    .supermodal__box {  
        max-width: 95vw !important; 
        min-width: 90vw !important;
    }

    .superform label.half {
        width: 100% !important
    }
}
@include breakpoint(laptop) {
    .supertoast {
        padding: 1.5rem 3rem !important;
        font-size: larger
    }
}
#statusUpdate{
    display: none
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
@import 'headfoot';
@import '1intro';  
@import 'contact';
@import 'sluzby';
@import 'petice';
@import 'lokality';
@import 'events';
@import 'sluzby';
@import 'projekt';
@import 'terminy';
@import 'elearning';