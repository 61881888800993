.lokality {
    min-height: 80vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &__box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;

        @include breakpoint(desktop) {
            width: 80vw;
        }

        @include breakpoint(tablet) {
            width: 90vw;
            margin-top: 5rem;
        }

        @include breakpoint(mobil) {
            width: 80vw;
        }

        span,
        p,
        h2 {
            text-align: center;
        }

        button {
            margin-top: 3rem;
        }

        h2 {
            margin: auto;
        }  

        .firmy {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3rem;
            padding: 6rem 2rem;
            z-index: 1;
            flex-wrap: wrap;

            img {
                max-width: 150px;
                object-fit: contain;
                object-position: bottom center;

                &.logo {
                    max-width: 200px;
                }

                @include breakpoint(mobil) {
                    max-width: 120px;
                }
            }

            a {
                &:nth-child(1) {
                    img {
                        max-width: 200px;

                        @include breakpoint(tablet) {
                            max-width: 150px;
                        }
                    }
                }
            }
        }
    }
}
