header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1rem 2.5rem 1rem 1rem;
    z-index: 5;
    background: linear-gradient(
        180deg,
        #2c2f46 0%,
        rgba(45, 48, 71, 0.02) 100%
    );
    z-index: 1;
    align-items: flex-start !important;

    @include breakpoint(mobil) {
        padding: 0.2rem 0.5rem;
    }

    .logo {
        min-width: 200px;
        max-width: 5rem;
        transition: all 0.5s ease-in-out;
        img {
            height: 100%;
            width: 100%;
        }
        @include breakpoint(maxitablet) {
            min-width: 210px;
        }
        @include breakpoint(tablet) {
            min-width: 190px;
        }
        @include breakpoint(mobil) {
            min-width: 150px;
        }
        &.scrolled {
            @include breakpoint(maxitablet) {
                min-width: 190px !important;
            }
            @include breakpoint(tablet) {
                min-width: 170px !important;
            }
            @include breakpoint(mobil) {
                min-width: 130px !important;
            }
        }
    }
    .hamburger {
        @include breakpoint(tablet) {
            margin-top: 2rem!important;
        }
        @include breakpoint(mobil) {
            margin-top: 1rem!important;
        }
    }
    .nav {
        gap: 5rem !important;
        align-items: center;

        @include breakpoint(maxitablet) {
            margin-top: 2rem!important;
            gap: 4rem !important;
        }
        a:not(.button) {
            color: white;

            text-transform: uppercase;
            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 0.5px;
                @include border;
                background-color: #ffdf22;
                border-radius: 2rem;
                outline-width: 1.5px;
                border-width: 0.3px;
                box-shadow: none;

                bottom: 5%;
                left: 0;
                pointer-events: none;
                transform-origin: 100% 50%;
                transform: scale3d(0, 1, 1);
                transition: transform 0.3s;
            }

            &:hover {
                &:before {
                    transform-origin: 0% 50%;
                    transform: scale3d(1, 1, 1);
                }
            }
        }

        a.button {
            padding: 0.45rem 1.55rem;
            font-size: 1.2rem;
        }
    }

    img {
        width: 130px;

        @include breakpoint(mobil) {
            width: 90px;
        }
    }

    .overlay a {
        font-size: 2rem;
        font-weight: bold;
        @include tilt;

        @include breakpoint(mobil) {
            font-size: 1.5rem;
        }
    }

    .overlay button {
        display: none;

        @include breakpoint(mobil) {
            display: block;
        }
    }
}

footer {
    background: #111a23;
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .footer {
        padding: 5rem 0;
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include breakpoint(mobil) {
            max-width: 90vw;
        }

        @include breakpoint(tablet) {
            max-width: 80vw;
            flex-direction: column;
            text-align: center;
            gap: 2rem;
        }

        @include breakpoint(desktop) {
            max-width: 70vw;
        }

        @include breakpoint(mobil) {
            padding: 3rem 0;
        }

        &__left {
            width: 100%;
            img {
                width: 100%;
                max-width: 269px;
            }
            @include breakpoint(maxitablet) {
                img {
                    width: 50%;
                    max-width: 300px;
                }
            }
            @include breakpoint(tablet) {
                img {
                    width: 55%;
                }
            }

            p {
                font-weight: 200;
                padding-top: 1rem;
                font-size: 0.8rem;
            }

            .container {
                display: flex;
                margin-bottom: 1rem;

                .vl {
                    border-left: 2px solid white;
                    margin: 0 1rem;
                }

                img:first-child {
                    filter: invert(1);
                }
            }
        }

        &__right {
            display: flex;
            gap: 6rem;
            width: 40%;

            button {
                all: unset;
            }

            @include breakpoint(tablet) {
                width: 100%;
                gap: 2rem;
                justify-content: center;
            }

            a,
            span {
                display: block;
                text-transform: uppercase;
                cursor: pointer;
                color: white;
                padding: 0.25rem 0;
            }

            a,
            span {
                width: fit-content;

                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 0.5px;
                    @include border;
                    background-color: #ffdf22;
                    border-radius: 2rem;
                    outline-width: 1.5px;
                    border-width: 0.3px;
                    box-shadow: none;

                    bottom: 5%;
                    left: 0;
                    pointer-events: none;
                    transform-origin: 100% 50%;
                    transform: scale3d(0, 1, 1);
                    transition: transform 0.3s;
                }

                &:hover {
                    &:before {
                        transform-origin: 0% 50%;
                        transform: scale3d(1, 1, 1);
                    }
                }
            }
        }
    }

    .copyright {
        width: 100%;
        padding: 1.5rem 5%;
        text-align: center;
        font-size: 0.7rem;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
    }
}
