header {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 99;

    .hamburger {
        all: unset;
        cursor: pointer;
        z-index: 9;

        .ham-menu {
            width: 50px;
            height: 50px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .line {
                border-radius: 1em;
                transition: all 0.3s ease-in-out;
                transform-origin: 1px;
                width: 100%;
                outline: 1.5px solid #FF9117;
                border: 1px solid #FFDF22;
                box-shadow: inset 0px 0px 0px 1.5px #FF9117;
            }
        }
    }

    .nav {
        // background: ${props =>props.bg||'transparent'};
        // gap: ${props =>props.gap||'10px'};
        // ${props =>props.moveRight&&'margin-left:auto;'}
        display: flex;
    }

    .overlayBg {
        position: fixed;
        z-index: 4;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        transition: all 0.3s ease-in-out;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .overlay {
        position: fixed;
        min-width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 5;
        transition: all 0.3s ease-in-out;
        top: 0;
        right: 0;
        background: linear-gradient(250deg, #1A1C31 0.33%, #111A23 100%), #111A23 !important;
    }
}