.sluzby {
    min-height: 80vh;
    width: 100%;
    margin: auto;
    margin-top: 8rem;
    margin-bottom: 8rem;
    display: flex;
    align-items: center;
    padding: 6rem 5% 0;
    justify-content: center;
    z-index: 1;

    @include breakpoint(tablet) {
        padding: 3rem 2.5% 0;
    }

    &__box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;
        gap: 4rem;
        width: 100%;

        span,
        p,
        h2 {
            text-align: center;
        }


        button,
        .button {
            margin-top: 3rem;

            @include breakpoint(mobil) {
                margin-top: 1.5rem;
            }
        }

        h2 {
            margin: auto;
        }


        .perex span {
            width: min-content;
            margin: auto;

        }

        &__cont {
            display: flex;
            justify-items: left;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            gap: 4rem;

            .service {
                border-radius: 32px;
                border: 3px solid #3e465c;
                width: 100%;
                max-width: 400px;
                background: linear-gradient(250deg, #1A1C31 0.33%, #111A23 100%);
                color: white;
                text-align: left;

                img {
                    border-radius: 32px 32px 0 0;
                    width: 100%;
                }

                @include breakpoint(desktop) {
                    max-width: 500px;
                }

                &__desc {
                    display: flex;
                    justify-items: left;
                    flex-direction: column;
                    margin: 3rem;
                    gap: .5rem;
                    max-width: 400px;

                    @include breakpoint(mobil) {
                        margin: 1.5rem;
                    }

                    p {
                        text-align: left !important;
                        min-height: 80px;
                    }

                    ul {
                        margin-left: 1rem;
                    }

                    .disabled {
                        cursor: default !important;

                        &:hover {
                            filter: none;
                        }
                    }
                }

                h3 {
                    color: white;
                    max-width: 350px;
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 1.5rem;
                    font-style: normal;
                    text-transform: uppercase;

                    @include breakpoint(mobil) {
                        font-size: 1.3rem;
                    }
                }
            }
        }


    }
}