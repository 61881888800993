.projekt {
    width: 100%;
    padding: 6rem 5%;
    display: flex;
    justify-content: center;
    margin-bottom: -400px;
    z-index: 2;

    @include breakpoint(tablet) {
        margin-bottom: -200px;
        padding: 3rem 2.5%;
    }

    @include breakpoint(mobil) {
        margin-bottom: -10rem;
    }

    &__box {
        //height: 100%;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        gap: 2rem;

        @include breakpoint(mobil) {
            width: 90%;
        }

        .perex,
        p {
            text-align: center;
            color: white;
            width: 100%;

        }

        .perex {
            padding: 1.5rem 1.5rem 2.5rem;

            @include breakpoint(tablet) {
                padding: 1.5rem 0 2.5rem;
            }

            @include breakpoint(mobil) {
                padding: 0;
                text-align: center;
            }
        }

        &__right {
            padding: 0 4rem 0;
            width: 100%;
            @include breakpoint(tablet) {
            padding: 0 1rem 0;}

            &::before {
                content: "";
                width: -webkit-fill-available;
                height: -webkit-fill-available;
                background: url("../assets/svg/VYBUCH.svg") no-repeat;
                background-position: left top;
                background-repeat: no-repeat;
                position: absolute;
                top: -75px;
                    left: -43px;
                mix-blend-mode: soft-light;
                transform: scale(-1, 1) rotate(-35deg);
                width: 120px;
                height: 150px;
                background-size: 100%;

                @include breakpoint(maxitablet) {
                    top: -75px;
                    left: -43px;
                    background-size: 80%;
                }

                @include breakpoint(tablet) {
                    top: -69px;
                    left: -95px;
                    background-size: 80%;
                }

                @include breakpoint(mobil) {
                    top: -59px;
                    left: -111pxpx;
                    background-size: 50%;
                }
            }

            &>div {
                outline: 3px solid #FF9117;
                border: 4px solid #FFDF22;
                box-shadow: inset 0px 0px 0px 3px #FF9117;
                border-radius: 1rem;
                padding: 5px;
                margin: auto;

                @include breakpoint(maxitablet) {
                    outline: 1.5px solid #FF9117;
                    border: 1.5px solid #FFDF22;
                    box-shadow: inset 0px 0px 0px 1.5px #FF9117;
                }

                @include breakpoint(mobil) {
                    outline: 1px solid #FF9117;
                    border: 1px solid #FFDF22;
                    box-shadow: inset 0px 0px 0px 1px #FF9117;
                }
                iframe {
                    border-radius: 1rem;
                }
            }
        }


    }
}