.events {
    width: 100%;
    padding: 6rem 5% 0;
    display: flex;
    justify-content: center; 
    z-index: 1;

    @include breakpoint(tablet) {
        padding: 3rem 2.5% 0;
    }

    &__box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        gap: 2rem;

        border-radius: 32px;
        border: 3px solid #3e465c;
        background: linear-gradient(250deg, #1A1C31 0.33%, #111A23 100%);
        padding: 20rem 14rem 8rem;

        button {
            margin: 3rem auto;
        }

        @include breakpoint(maxitablet) {
            padding: 18rem 7rem 5rem;
        }

        @include breakpoint(tablet) {
            padding: 10rem 1.5rem 5rem;
        } 

    }

    &__container {
        display: flex;
        gap: inherit;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        margin-bottom: 3rem;

        @include breakpoint(laptop) {
            flex-direction: row; 
        }
        @include breakpoint(tablet) {
            flex-direction: column;
        }
        @include breakpoint(maxitablet) {
            justify-content: left;           
        }
 
    }
}

.event {
    background-color: transparent;
    border-radius: 1.7rem;
    padding: 1.3rem;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include border;
    border-width: 2px;
    outline-width: 3px;
    box-shadow: inset 0px 0px 0px 3px #FF9117;
    font-size: 1.1rem;
    min-width: 20vw;
    width: 100%;
    filter: drop-shadow(0px 0px 2px rgba(255, 145, 23, 1));
 
    @include breakpoint(maxitablet) {
        min-width: 350px;
    }
    @include breakpoint(tablet) {
        min-width: 200px;
        border-width: 1.5px;
        outline-width: 1.5px;
        box-shadow: inset 0px 0px 0px 1.5px #FF9117;
    }

    @include breakpoint(mobil) {
        font-size: 0.8rem;
        max-width: 100%;
        min-width: 0;
        gap: 1rem;
    }

    &:hover {
        filter: drop-shadow(0px 0px 4px rgba(255, 145, 23, 1));
        transition: all 0.3s ease-in;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;

    }

    &__body {
        width: 100%;

        h2 {
            font-size: 1.8rem;
            text-wrap: wrap;
            text-transform: uppercase;

            @include breakpoint(mobil) {
                font-size: 1.5rem;
                line-height: 1.4;
            }
        }

        span {

            text-wrap: wrap;

            @include breakpoint(mobil) {
                font-size: 1rem;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            max-width: 50px;
        }
    }

    button {
        margin: 0;
        padding: 0;
    }
}